import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';

dayjs.extend(RelativeTime);
dayjs.extend(UTC);
dayjs.extend(Timezone);

export default dayjs;
