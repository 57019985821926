export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type CountUnreadNotifications = {
  __typename?: 'CountUnreadNotifications';
  tab: NotificationTab;
  value: Scalars['Int']['output'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  date: Scalars['String']['output'];
  docNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  value: InvoiceValue;
};

export type InvoiceValue = {
  __typename?: 'InvoiceValue';
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type InvoicingInfo = {
  __typename?: 'InvoicingInfo';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Country;
  isPerson: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelSubscription: Scalars['Boolean']['output'];
  changePaymentMethod: Scalars['String']['output'];
  clearAllNotifications: Scalars['Boolean']['output'];
  clearNotification: Scalars['Boolean']['output'];
  confirmUserChangeEmail: Scalars['Boolean']['output'];
  deleteAccount: Scalars['Boolean']['output'];
  deleteInvoicingInfo: Scalars['Boolean']['output'];
  deleteUserPicture: Scalars['Boolean']['output'];
  deleteWorkspacePicture: Scalars['Boolean']['output'];
  downloadInvoice: Scalars['String']['output'];
  exportUserData: Scalars['String']['output'];
  logout: Scalars['Boolean']['output'];
  readAllNotifications: Scalars['Boolean']['output'];
  readNotification: Scalars['Boolean']['output'];
  requestUserChangeEmail: Scalars['Boolean']['output'];
  resendRegisterConfirmEmail: Scalars['Boolean']['output'];
  subscribe: Subscribe;
  switchWorkspace: Workspace;
  unclearNotification: Scalars['Boolean']['output'];
  unreadNotification: Scalars['Boolean']['output'];
  updateAccountTimezone: User;
  updateBillingEmail?: Maybe<Scalars['String']['output']>;
  updateInvoicingInfo: InvoicingInfo;
  updateLanguage: User;
  updateUserInfo: User;
  updateUserPicture: Scalars['Boolean']['output'];
  updateWorkspaceInfo: Scalars['Boolean']['output'];
  updateWorkspaceLanguage: Scalars['Boolean']['output'];
  updateWorkspacePicture: Scalars['Boolean']['output'];
};


export type MutationClearAllNotificationsArgs = {
  tab?: NotificationTab;
};


export type MutationClearNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationConfirmUserChangeEmailArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAccountArgs = {
  password: Scalars['String']['input'];
};


export type MutationDownloadInvoiceArgs = {
  docNumber: Scalars['String']['input'];
};


export type MutationReadAllNotificationsArgs = {
  tab?: NotificationTab;
};


export type MutationReadNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestUserChangeEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSubscribeArgs = {
  priceRef: Scalars['String']['input'];
};


export type MutationSwitchWorkspaceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  idLegacy?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUnclearNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnreadNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAccountTimezoneArgs = {
  timezoneId: Scalars['String']['input'];
};


export type MutationUpdateBillingEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateInvoicingInfoArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  isPerson?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationUpdateLanguageArgs = {
  languageId: Scalars['String']['input'];
};


export type MutationUpdateUserInfoArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateUserPictureArgs = {
  filename: Scalars['String']['input'];
};


export type MutationUpdateWorkspaceInfoArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateWorkspaceLanguageArgs = {
  languageId: Scalars['String']['input'];
};


export type MutationUpdateWorkspacePictureArgs = {
  filename: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  single?: Maybe<SingleNotification>;
  stacked?: Maybe<StackedNotification>;
};

export enum NotificationArea {
  AdsAnalytics = 'ADS_ANALYTICS',
  Analytics = 'ANALYTICS',
  Calendar = 'CALENDAR',
  CompetitorsAlerts = 'COMPETITORS_ALERTS',
  CompetitorAnalytics = 'COMPETITOR_ANALYTICS',
  Drafts = 'DRAFTS',
  Feed = 'FEED',
  General = 'GENERAL',
  Inbox = 'INBOX',
  Influencer = 'INFLUENCER',
  ProduceContent = 'PRODUCE_CONTENT',
  Profiles = 'PROFILES',
  Rss = 'RSS',
  TeamManagement = 'TEAM_MANAGEMENT',
  TeamWorkflow = 'TEAM_WORKFLOW'
}

export enum NotificationButton {
  InstantDownload = 'INSTANT_DOWNLOAD',
  MultiRedirect = 'MULTI_REDIRECT',
  Redirect = 'REDIRECT'
}

export enum NotificationTab {
  All = 'ALL',
  Cleared = 'CLEARED',
  Warning = 'WARNING'
}

export enum NotificationVariant {
  Error = 'ERROR',
  General = 'GENERAL',
  Info = 'INFO',
  Success = 'SUCCESS',
  User = 'USER',
  Warning = 'WARNING'
}

export type PaginatedGroupNotifications = {
  __typename?: 'PaginatedGroupNotifications';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<SingleNotification>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedInvoice = {
  __typename?: 'PaginatedInvoice';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Invoice>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Notification>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaymentDefaultMethod = {
  __typename?: 'PaymentDefaultMethod';
  card?: Maybe<PaymentMethodCard>;
  paymentMethod: PaymentMethod;
  paypal?: Maybe<PaymentMethodPaypal>;
};

export enum PaymentMethod {
  Card = 'CARD',
  Other = 'OTHER',
  Paypal = 'PAYPAL'
}

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expiration: Scalars['String']['output'];
  expired: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethodPaypal = {
  __typename?: 'PaymentMethodPaypal';
  email: Scalars['String']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['String']['output'];
  isTrial: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum PlanLimit {
  AndieCredits = 'ANDIE_CREDITS',
  CompetitorsProfiles = 'COMPETITORS_PROFILES',
  SocialProfiles = 'SOCIAL_PROFILES',
  TeamMembers = 'TEAM_MEMBERS'
}

export type PlanLimits = {
  __typename?: 'PlanLimits';
  current: Scalars['Int']['output'];
  key: PlanLimit;
  limit: Scalars['Int']['output'];
};

export type PlanPreview = {
  __typename?: 'PlanPreview';
  code: Scalars['String']['output'];
  mostPopular: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  plan: Plan;
  prices: Array<PlanPreviewPrice>;
};

export type PlanPreviewPrice = {
  __typename?: 'PlanPreviewPrice';
  currency: Currency;
  discount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  interval: PlanPriceInterval;
  isSubscribed: Scalars['Boolean']['output'];
  monthlyPrice: Scalars['Float']['output'];
};

export enum PlanPriceInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type Query = {
  __typename?: 'Query';
  countUnreadNotifications: Array<CountUnreadNotifications>;
  countries: Array<Country>;
  currentWorkspace: Workspace;
  defaultPaymentMethod?: Maybe<PaymentDefaultMethod>;
  groupNotifications: PaginatedGroupNotifications;
  hasActiveSubscription: Scalars['Boolean']['output'];
  hasEmailValidated: Scalars['Boolean']['output'];
  hasFailedPayment: Scalars['Boolean']['output'];
  hasInvoicingTaxesChanged: Scalars['Boolean']['output'];
  invoices: PaginatedInvoice;
  invoicingInfo: InvoicingInfo;
  languages: Array<Language>;
  me: User;
  notification: Notification;
  notifications: PaginatedNotifications;
  planLimits: Array<PlanLimits>;
  plans: Array<PlanPreview>;
  subscriptionPreview?: Maybe<SubscriptionPreview>;
  teamPermissions: Array<TeamPermission>;
  timezones: Array<Timezone>;
  uploadUrls: Array<UploadUrl>;
  workspaces: WorkspacePaginated;
};


export type QueryGroupNotificationsArgs = {
  group: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHasInvoicingTaxesChangedArgs = {
  countryId: Scalars['String']['input'];
  isPerson?: Scalars['Boolean']['input'];
  postalCode: Scalars['String']['input'];
};


export type QueryInvoicesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  tab?: NotificationTab;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUploadUrlsArgs = {
  filenames: Array<Scalars['String']['input']>;
};


export type QueryWorkspacesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SingleNotification = {
  __typename?: 'SingleNotification';
  actions?: Maybe<Array<SingleNotificationActions>>;
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  group: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  variant: NotificationVariant;
};

export type SingleNotificationActions = {
  __typename?: 'SingleNotificationActions';
  data: Scalars['JSON']['output'];
  nameKey: Scalars['String']['output'];
  type: NotificationButton;
};

export type StackedNotification = {
  __typename?: 'StackedNotification';
  area: NotificationArea;
  count: Scalars['Int']['output'];
  group: Scalars['Int']['output'];
  isRead: Scalars['Boolean']['output'];
  last2: Array<StackedNotificationLast2>;
  variant: NotificationVariant;
};

export type StackedNotificationLast2 = {
  __typename?: 'StackedNotificationLast2';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
};

export type Subscribe = {
  __typename?: 'Subscribe';
  redirectToSubscribe: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPreview = {
  __typename?: 'SubscriptionPreview';
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<Currency>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  interval?: Maybe<PlanPriceInterval>;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  nextPaymentAmount?: Maybe<Scalars['Float']['output']>;
  plan: Plan;
  status: SubscriptionPreviewStatus;
};

export enum SubscriptionPreviewStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Overdue = 'OVERDUE'
}

export enum TeamPermission {
  Billing = 'BILLING',
  Owner = 'OWNER'
}

export type Timezone = {
  __typename?: 'Timezone';
  id: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  timezoneKey: Scalars['String']['output'];
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  filename: Scalars['String']['output'];
  newFilename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  color: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Language;
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  publicId: Scalars['Int']['output'];
  socketToken: Scalars['String']['output'];
  timezone?: Maybe<Timezone>;
};

export type Workspace = {
  __typename?: 'Workspace';
  billingEmail?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  language: Language;
  name: Scalars['String']['output'];
  owner: User;
  picture?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  totalMembers: Scalars['Int']['output'];
};

export type WorkspacePaginated = {
  __typename?: 'WorkspacePaginated';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Workspace>>;
  total?: Maybe<Scalars['Int']['output']>;
};
