import { useSession } from '@/providers/session-provider';
import { isset } from '@/legacy/utils/utils';
import { createRef, useEffect, useRef } from 'react';
import { CallCallbackParams } from '../tours/tour-controller';
import { PostHogActionProperties } from '../tours/posthog-action-properties';
import posthog, { Properties } from 'posthog-js';
import { useApolloClient, useMutation } from '@apollo/client';
import { LogoutDocument } from '@/graphql/generated/logout.generated';
import { SwitchWorkspaceDocument } from '@/graphql/generated/switch-workspace.generated';
import { enqueueSnackbar } from 'notistack';
import { sendMessage as otherIframeSendMessage } from './LegacyIframe';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LegacyPath } from './LegacyIframes';

let iframe: Window | null = null;
const oldHost: string = import.meta.env.VITE_OLD_LEGACY_APP_ROOT as string;

const pathMatch: LegacyPath[] = [
    {
        oldPath: 'new_dashboard',
        newPath: 'dashboard',
    },
    {
        oldPath: 'drafts',
        newPath: 'planning/drafts',
    },
    {
        oldPath: 'queue',
        newPath: 'planning/queue',
    },
    {
        oldPath: 'produce_content',
        newPath: 'produce-content',
    },
    {
        oldPath: 'team_management/pool',
        newPath: 'planning/workflow-pool',
    },
    {
        oldPath: 'team_management/posts',
        newPath: 'planning/posts-workflow',
    },
    {
        oldPath: 'schedule',
        newPath: 'planning/calendar',
    },

    {
        oldPath: 'new_analytics/overview',
        newPath: 'reports/basic',
    },
    {
        oldPath: 'new_analytics/dashboards',
        newPath: 'reports/advanced',
    },
    {
        oldPath: 'new_analytics/ads_overview',
        newPath: 'reports/ads-overview',
    },
    {
        oldPath: 'new_analytics/ads_dashboards',
        newPath: 'reports/ads-campaigns',
    },
    {
        oldPath: 'competitors/analytics',
        newPath: 'reports/competitors',
    },
    {
        oldPath: 'new_analytics',
        newPath: 'reports',
    },

    {
        oldPath: 'dashboard/inbox',
        newPath: 'inbox',
    },
    {
        oldPath: 'dashboard/feed',
        newPath: 'feeds/posts',
    },
    {
        oldPath: 'dashboard/posts',
        newPath: 'feeds/my-posts',
    },
    {
        oldPath: 'dashboard/rss',
        newPath: 'feeds/rss-feed',
    },

    {
        oldPath: 'competitors/monitoring',
        newPath: 'feeds/competitor-monitoring',
    },

    {
        oldPath: 'competitors',
        newPath: 'feeds/competitor-monitoring',
    },

    {
        oldPath: 'dashboard',
        newPath: 'feeds',
    },

    {
        oldPath: 'manage_social_networks',
        newPath: 'settings/workspace/social-profile',
    },
    {
        oldPath: 'personal_settings/account_status',
        newPath: 'settings/workspace/account-status',
    },
    {
        oldPath: 'team_management/management',
        newPath: 'settings/workspace/team',
    },
    {
        oldPath: 'team_management',
        newPath: 'settings/workspace/team',
    },
    {
        oldPath: 'personal_settings/integrations',
        newPath: 'settings/workspace/integrations',
    },
    {
        oldPath: 'personal_settings/notifications',
        newPath: 'settings/personal/notifications',
    },
    {
        oldPath: 'manage_channels',
        newPath: 'settings/workspace/groups',
    },
    {
        oldPath: 'personal_settings/settings/post',
        newPath: 'settings/workspace/rss-content',
    },
    {
        oldPath: 'personal_settings/settings/tokens',
        newPath: 'settings/workspace/api-keys',
    },
    {
        oldPath: 'personal_settings',
        newPath: 'settings/personal/security',
    },
    {
        oldPath: 'team_management',
        newPath: 'team',
    },
];

export { pathMatch };

interface OldLegacySyncAction {
    id?: string;
    type?: string;
    value?: string | null;
    selector?: string | null;
    html?: string | undefined;
    rect?: CSSStyleDeclaration;
    tour?: string;
    status?: boolean;
    set?: Properties;
}

const OldLegacyIframe = ({
    showBackdrop,
    setShowBackdrop,
}: Readonly<{
    showBackdrop: boolean;
    setShowBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
}>) => {
    const { t } = useTranslation();
    const pathName = useLocation().pathname;
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const target = oldHost;
    const ref = createRef<HTMLIFrameElement>();

    const session = useSession();

    const reactPaths = ['listening', 'export', 'offers', 'discover', 'badges'];

    const excludePaths = ['competitors/monitoring'];

    const [logoutMutation] = useMutation(LogoutDocument);
    const [switchWorkspaceMutation] = useMutation(SwitchWorkspaceDocument);

    const logout = () => {
        logoutMutation({
            onCompleted: (data) => {
                if (data.logout) {
                    window.location.href = `${import.meta.env.VITE_HOMEPAGE_ROOT}/logout`;
                } else {
                    enqueueSnackbar(t('error.something-wrong'));
                }
            },
            onError: (e) => enqueueSnackbar(t(e.message)),
        });
    };

    const switchWorkspace = (idLegacy: number) => {
        switchWorkspaceMutation({
            variables: { idLegacy },
            onCompleted: () => {
                navigate('/', { replace: true });
                apolloClient.resetStore().then(() => {
                    session.refetch();
                    otherIframeSendMessage({ type: 'reload' });
                    sendMessage({ type: 'reload' });
                });
            },
            onError: (e) => enqueueSnackbar(t(e.message)),
        });
    };

    /* Session.setCallback(() => {
            sendMessage({ type: 'reload' });
            setTimeout(() => setPostHogIdentify(), 1000);
        }); */

    useEffect(() => {
        if (ref != null) iframe = ref!.current!.contentWindow;
        window.addEventListener(
            'message',
            (event) => {
                if (target.indexOf(event.origin) !== -1 && isset(event.data) && isset(event.data.path)) {
                    let path = event.data.path.replace('#', '');

                    path = getPagePath(path, 'new');
                    if (window.location.pathname !== path) {
                        navigate(path, { replace: true });
                    }

                    legacySyncActions(isset(event.data.action, {}));
                }
            },
            false,
        );

        setTimeout(() => {
            lastPath.current = getPagePath();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isOldLegacy = pathMatch.some((paths) => {
        return (
            null != matchPath({ path: `/${paths.newPath}/*` }, pathName) ||
            null != matchPath({ path: `/${paths.oldPath}/*` }, pathName)
        );
    });

    const legacySyncActions = (data: OldLegacySyncAction) => {
        switch (isset(data.type, '')) {
            case 'switchAccount':
                if (data.id) {
                    switchWorkspace(parseInt(data.id));
                }
                break;
            case 'event':
                document.dispatchEvent(new Event(isset(data.id, 'click')));
                break;
            case 'fullscreen':
                if (document.querySelector('.Top.Bar')) {
                    document.querySelector<HTMLElement>('.Top.Bar')!.style.display = isset(data.value, false)
                        ? 'none'
                        : '';
                }
                break;
            case 'supportChat':
                (window as any).toggleChat(isset(data.value, true)); // eslint-disable-line @typescript-eslint/no-explicit-any
                break;
            case 'refreshUserData':
                session.refetch();
                break;
            case 'setDOM':
                if (data.selector && data.html && data.rect) {
                    if (document.getElementById('legacyElementFaker') !== null) {
                        document.getElementById('legacyElementFaker')!.remove();
                    }

                    const div = document.createElement('div');
                    div.id = 'legacyElementFaker';
                    div.innerHTML = data.html;
                    div.dataset.id = data.id;
                    div.style.position = 'absolute';
                    div.style.opacity = '0';
                    div.style.pointerEvents = 'none';
                    div.style.width = data.rect.width + 'px';
                    div.style.height = data.rect.height + 'px';

                    const topValue = Number(
                        data.rect.top + document.getElementById('oldPageIframe')!.getBoundingClientRect().top,
                    );
                    const leftValue = Number(
                        data.rect.left +
                            document.getElementById('oldPageIframe')!.getBoundingClientRect().left,
                    );
                    div.style.top = topValue + 'px';
                    div.style.left = leftValue + 'px';
                    div.style.overflow = 'hidden';

                    const rectHeight = Number(data.rect.height);
                    if (topValue + rectHeight > window.innerHeight) {
                        const outsideMargin = topValue + rectHeight - window.innerHeight;
                        if (rectHeight > outsideMargin) {
                            div.style.height = rectHeight - outsideMargin + 'px';
                        } else {
                            div.style.height = '10px';
                            div.style.top = window.innerHeight - 10 + 'px';
                        }
                    }

                    document.body.appendChild(div);
                }
                break;
            //TODO Commented while a solution is found for tours
            /* case 'tourController':
                if (data.tour) {
                    TourController.load(data.tour, (data) => sendMessage({ type: 'tourController', data }));
                }
                break;
            case 'tourController.status':
                sendMessage({ type: 'tourController.status', data: TourController.status() });
                break;
            case 'tourController.setStatus':
                if (data.tour && data.status) {
                    TourController.setStatus(data.status ? 'true' : 'false', data.tour);
                }
                break; */
            case 'popup': {
                const showPopup: boolean = isset(data.value, false);
                setShowBackdrop(showPopup);
                sendMessage({ type: 'popupsStatus', popup: showPopup });
                break;
            }
            case 'postHogSetPropertyFlags':
                if (data.set) {
                    posthog.setPersonPropertiesForFlags(data.set);
                    posthog.reloadFeatureFlags();
                }
                break;
            case 'logout':
                logout();
                break;
            case 'upgrade-plan':
                navigate('/settings/workspace/billing', { replace: true });
                break;
            case 'showOldLegacy':
                if (!isOldLegacy) {
                    navigate('/dashboard', { replace: true });
                }
                break;

            default:
                break;
        }
    };

    const lastPath = useRef('');

    const syncPaths = () => {
        const path = getPagePath();
        if (lastPath.current !== path) {
            sendMessage({ path: path });
            lastPath.current = path;
        }
    };

    const getPagePath = (path?: string | null, type?: string, setState?: boolean) => {
        type = type || 'old';
        const inverseType = type === 'old' ? 'new' : 'old';
        let auxPath = path || pathName;

        setState = isset(setState, true);

        const allReactPaths = JSON.parse(JSON.stringify(reactPaths));

        let excludePath = false;
        for (const tempExcludePath of excludePaths) {
            if ((path || pathName).includes(tempExcludePath)) {
                excludePath = true;
            }
        }

        if (!(allReactPaths.indexOf(auxPath) !== -1 && !excludePath)) {
            for (const paths of pathMatch) {
                const invertedTypeIndex = (inverseType + 'Path') as keyof LegacyPath;
                const typeIndex = (type + 'Path') as keyof LegacyPath;
                if (
                    auxPath.startsWith('/' + paths[invertedTypeIndex] + '/') ||
                    auxPath.endsWith(paths[invertedTypeIndex])
                ) {
                    if (typeof paths[typeIndex] === 'string') {
                        auxPath = auxPath.replace('/' + paths[invertedTypeIndex], '/' + paths[typeIndex]);
                        break;
                    }
                }
            }
        }

        if (setState) {
            preventHistoryRender.current = true;
            setTimeout(() => (preventHistoryRender.current = false), 100);
        }

        return auxPath;
    };

    /*isIframeLoaded = false;
     setPostHogIdentify() {
        var data = Session.getParams();
    
        if (isset(data.id) && isIframeLoaded) {
            var teams = Array.isArray(data.teams) ? data.teams.map(function (o) { return o.id; }) : [];
    
            sendMessage({
                type: 'postHogIdentify',
                id: data.id.toString(),
                set: {
                    name: data.name,
                    email: data.email,
                    activeTeam: data.activeTeam,
                    language: data.language,
                    teams
                }
            });
        }
    } */

    const preventHistoryRender = useRef(true);

    useEffect(() => {
        if (!preventHistoryRender.current) {
            syncPaths();
        }
    });

    return (
        <iframe
            title="Page"
            id="oldPageIframe"
            allow="clipboard-write"
            width={'100%'}
            height={'100%'}
            src={target + '/#' + getPagePath(null, 'old', false)}
            ref={ref}
            style={{
                border: '0px solid',
                flex: '1 1 auto',
                ...(showBackdrop && {
                    zIndex: 1401,
                }),
            }}
            onLoad={() => {
                /* isIframeLoaded = true;
                setPostHogIdentify(); */
            }}
        ></iframe>
    );
};

export default OldLegacyIframe;

type SendMessageSet = {
    name: string;
    email: string;
    activeTeam: number | null;
    language: string;
    teams: Array<number>;
};
type SendMessageData = {
    type?: string;
    path?: string;
    className?: string;
    id?: string;
    set?: SendMessageSet | PostHogActionProperties;
    selector?: string | HTMLElement;
    data?: CallCallbackParams | { name: string | null; running: boolean };
    popup?: boolean;
};

export function sendMessage(data: SendMessageData) {
    if (iframe) {
        iframe.postMessage(data, oldHost);
    }
}

export function deleteFakeElement() {
    if (document.getElementById('legacyElementFaker') !== null) {
        document.getElementById('legacyElementFaker')!.remove();
    }
}

export function redirectHashroutes() {
    // Redirect Old Legacy urls hash routes
    if (typeof window !== 'undefined') {
        if (window.location.hash.includes('#/')) {
            const routeWithoutHash = window.location.hash.replace('#', '');
            window.location.replace(routeWithoutHash);
        }
    }
}
